export interface SettingsBuilderSection {
	title: string;
	hasAccess: boolean;
	items: SettingsItem[];
	testId?: string;
}

export interface SettingsItem {
	title: React.ReactNode;
	subtitle?: string;
	aliases: string[];
	hasAccess: boolean;
	route?: string;
	external?: string;
	testId?: string;
	isNew?: boolean;
}

export const filterSettings = (sections: SettingsBuilderSection[], searchFilter: string): SettingsBuilderSection[] => {
	return sections
		.filter(
			s =>
				s.hasAccess &&
				s.items.some(i => i.hasAccess && i.aliases.some(a => a.toLowerCase().includes(searchFilter.toLowerCase()))),
		)
		.map(s => {
			return {
				...s,
				items: s.items.filter(i => i.aliases.some(a => a.toLowerCase().includes(searchFilter.toLowerCase()))),
			};
		});
};

export class ItemBuilder {
	title: React.ReactNode;
	subtitle?: string;
	route?: string;
	hasAccess: boolean;
	aliases: string[];
	section: SectionBuilder;
	external?: string;
	testId?: string;
	isNew?: boolean;
	requireAccess = (access: boolean) => {
		this.hasAccess = access;
		return this;
	};

	setExternalRoute = (route: string) => {
		this.external = route;
		return this;
	};

	addItem = (
		title: React.ReactNode,
		aliases: string[],
		route?: string,
		subtitle?: string,
		testId?: string,
		isNew?: boolean,
	) => {
		return this.section.addItem(title, aliases, route, subtitle, testId, isNew);
	};

	constructor(
		title: React.ReactNode,
		aliases: string[],
		section: SectionBuilder,
		route?: string,
		subtitle?: string,
		testId?: string,
		isNew?: boolean,
	) {
		this.title = title;
		this.section = section;
		this.aliases = aliases;
		this.aliases.push(section.title); // When searching for section name, show all items
		this.route = route;
		this.hasAccess = false; // Default deny
		this.subtitle = subtitle;
		this.testId = testId;
		this.isNew = isNew;
	}
}

export class SectionBuilder {
	title: string;
	items: ItemBuilder[] = [];
	hasAccess: boolean;
	testId?: string;
	paidPlanRequired?: boolean;

	constructor(title: string, hasAccess: boolean, testId?: string) {
		this.title = title;
		this.hasAccess = hasAccess;
		this.testId = testId;
	}

	addItem = (
		title: React.ReactNode,
		aliases: string[],
		route?: string,
		subtitle?: string,
		testId?: string,
		isNew?: boolean,
	) => {
		const i: ItemBuilder = new ItemBuilder(title, aliases, this, route, subtitle, testId, isNew);
		this.items.push(i);
		return i;
	};

	requireAccess = (access: boolean) => {
		this.hasAccess = access;
		return this;
	};
}

export class SettingsBuilder {
	sections: SectionBuilder[] = [];

	addSection = (title: string, testId?: string) => {
		const s = new SectionBuilder(title, false, testId);
		this.sections.push(s);
		return s;
	};

	build = (): SettingsBuilderSection[] => {
		return this.sections.map(
			(s): SettingsBuilderSection => ({
				title: s.title,
				hasAccess: s.hasAccess,
				testId: s.testId,
				items: s.items.map(
					(item): SettingsItem => ({
						title: item.title,
						subtitle: item.subtitle,
						aliases: item.aliases,
						route: item.route,
						hasAccess: item.hasAccess,
						external: item.external,
						testId: item.testId,
						isNew: item.isNew,
					}),
				),
			}),
		);
	};
}
