import * as React from "react";
import { Key } from "w3c-keys";

import Field from "@bokio/elements/Form/Field/Field";
import { InputField } from "@bokio/elements/Form/InputField";
import LabelFor from "@bokio/elements/Form/LabelFor/LabelFor";
import Icon from "@bokio/elements/Icon/Icon";
import { GeneralLangFactory } from "@bokio/lang";
import { Day } from "@bokio/mobile-web-shared/core/model/model";
import { dayLocaleFormat } from "@bokio/mobile-web-shared/core/model/types";
import { formatDate } from "@bokio/shared/utils/format";
import { mergeClassNames } from "@bokio/utils/classes";

import DatePicker from "../DatePicker/DatePicker";
import { useDeviceQuery } from "../DeviceQuery/useDeviceQuery";
import { getFormatHandlers } from "./formatHandlers";

import type { InputFieldPropsBase } from "@bokio/elements/Form/InputField";
import type { LabelTooltip } from "@bokio/elements/Form/LabelFor/LabelFor";
import type * as DayPicker from "react-day-picker";

import * as styles from "./dayInput.scss";

export interface DayInputProps extends InputFieldPropsBase<Day | undefined> {
	maxDate?: Day;
	minDate?: Day;
	alignCal?: "left" | "right";
	openAbove?: boolean;
	mobileTestId?: string;
	disabledDaysRange?: DayPicker.RangeModifier[];
	maxMonth?: Date;
	minMonth?: Date;
	monthToStartFrom?: Day;
	labelTooltip?: LabelTooltip;
	defaultDate?: Day;
}

const formatHandlers = getFormatHandlers();

const DayInput = (props: DayInputProps) => {
	const {
		disabled,
		label,
		errors,
		hint,
		labelClassName,
		className,
		minDate,
		maxDate,
		alignCal,
		testId,
		value,
		openAbove,
		mobileTestId,
		labelTooltip,
		labelTestId,
		defaultDate,
	} = props;

	const [showDatePicker, setShowDatePicker] = React.useState(false);

	const setDay = (day: Day | undefined) => {
		props.onChange && props.onChange(day);
		setShowDatePicker(false);
	};

	const changeDate = (change: number, fast?: boolean) => {
		if (!value) {
			return setDay(defaultDate ?? Day.today());
		}

		const dayChange = fast ? 0 : change;
		const monthChange = fast ? change : 0;

		const newDay = Day.addMonths(Day.addDays(value && value.isValid() ? value : Day.today(), dayChange), monthChange);

		const isValid = (minDate ? newDay.dayDiff(minDate) >= 0 : true) && (maxDate ? newDay.dayDiff(maxDate) <= 0 : true);

		if (isValid && newDay.isValid()) {
			setDay(newDay);
		}
	};
	const increment = () => changeDate(+1);
	const decrement = () => changeDate(-1);

	const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
		if (e.key === Key.ArrowUp) {
			changeDate(+1, e.shiftKey);
			e.preventDefault();
		}
		if (e.key === Key.ArrowDown) {
			changeDate(-1, e.shiftKey);
			e.preventDefault();
		}
	};

	const parseDateAndSet = (value: string) => {
		const trimmed = value.replace(/ /g, "");

		const parsedDay = Day.parseString(trimmed);
		trimmed ? setDay(parsedDay) : setDay(undefined);
	};

	const handleMobileInputChange = (value: string) => {
		const parsedDay = Day.parseString(value, { iso: true });
		// Since `value` here is *always* ISO-formatted we need to reformat so Day.getRawValue() outputs local format
		const formatted = parsedDay.format();
		parseDateAndSet(formatted);
	};

	const handleInputChange = (value: string) => parseDateAndSet(value);

	const handleInputBlur = () => {
		if (value && value.isValid()) {
			return;
		}

		const trimmed = (value ? value.getRawValue() : "").replace(/ /g, "");
		const customDay = formatHandlers.map(handler => handler.tryHandle(trimmed)).find(day => day && day.isValid());

		if (customDay) {
			setDay(customDay);
		}
	};

	const toggleDatePicker = () => setShowDatePicker(show => !show);

	const lang = GeneralLangFactory();

	const showDatePickerStyle = mergeClassNames(
		styles.overlayShow,
		alignCal === "left" && styles.overlayLeft,
		openAbove && styles.overlayAbove,
	);

	const { isDesktop } = useDeviceQuery({ minDesktopWidth: 1024 });

	return (
		<Field className={props.wrapperClassName}>
			<LabelFor
				label={label}
				hint={hint}
				errors={errors && errors.errors}
				className={labelClassName}
				labelTooltip={labelTooltip}
				testId={labelTestId}
			>
				<div
					className={mergeClassNames(styles.wrapper, styles.hideOnMobile, disabled && styles.disabled, className)}
					onKeyDown={handleKeyDown}
					data-testid="DayInput_KeyHandler"
				>
					<div className={styles.inputWrapper}>
						<InputField
							{...props}
							wrapperClassName={undefined}
							label=""
							value={value ? value.getRawValue() : ""}
							placeholder={dayLocaleFormat}
							onChange={handleInputChange}
							onBlur={handleInputBlur}
							disabled={disabled}
							errors={undefined}
							className={styles.inputDesktop}
							testId={testId}
							fieldProps={{ autoComplete: "off", ...props.fieldProps }}
						/>
						<div className={styles.border} />
						<div className={styles.indicators}>
							<button
								type="button"
								tabIndex={-1}
								title={lang.DayPicker_ShortcutDown_label}
								disabled={disabled}
								className={styles.firstIndicator}
								onClick={decrement}
								data-testid="DayInput_Decrease_Button"
							>
								<Icon name="down-open-big" size="18" />
							</button>
							<button
								type="button"
								tabIndex={-1}
								title={lang.DayPicker_ShortcutUp_label}
								disabled={disabled}
								className={styles.indicator}
								onClick={increment}
								data-testid="DayInput_Increase_Button"
							>
								<Icon name="up-open-big" size="18" />
							</button>
						</div>
					</div>
					<button
						type="button"
						tabIndex={-1}
						className={styles.button}
						disabled={disabled}
						onClick={toggleDatePicker}
						data-testid={"DayInput_ToggleButton"}
					>
						<Icon name="calendar" size="18" />
					</button>
					<div className={showDatePicker && !disabled ? showDatePickerStyle : styles.overlay}>
						{showDatePicker && (
							<DatePicker
								onChange={date => setDay(Day.fromDate(date))}
								value={(value && value.isValid() ? value : Day.today()).toDate()}
								month={
									props.monthToStartFrom
										? props.monthToStartFrom.toDate()
										: (value && value.isValid() ? value : Day.today()).toDate()
								}
								minDate={minDate ? minDate.toDate() : undefined}
								maxDate={maxDate ? maxDate.toDate() : undefined}
								disabledDaysRange={props.disabledDaysRange}
								maxMonth={props.maxMonth}
								minMonth={props.minMonth}
							/>
						)}
					</div>
				</div>

				{/*
					Why do we not set the min and max values when it's not mobile?
					There is an issue that occurs on Chrome that can result in us not being able to submit a form.

					The issue is that when we set min and max values on an input field that browser
					will automatically try to validate those fields when submitting the form. The problem happens
					when the value is outside of the min and max values because then Chrome tries to show an
					error message next to the field, but seeing as this mobile-only field is hidden Chrome
					fails and it results in the error "An invalid form control with name='' is not focusable"

					This is an issue because the user doesn't see what's wrong due to the field being hidden.
					It's also an issue because we can't submit the form due to the error in Chrome.

					The solution is to skip the validation when the input field is hidden and only add it
					when it's actually visible.

					More details about this can be found here: https://stackoverflow.com/questions/22148080/an-invalid-form-control-with-name-is-not-focusable
				*/}

				{!isDesktop && (
					<div className={mergeClassNames(styles.mobileWrapper, styles.showOnMobile)}>
						<input
							className={styles.mobileInput}
							type="date"
							min={formatDate(minDate)}
							max={formatDate(maxDate)}
							value={value ? value.toISOString() : ""}
							onChange={e => handleMobileInputChange(e.target.value)}
							placeholder={dayLocaleFormat}
							disabled={disabled}
							data-testid={mobileTestId}
						/>
					</div>
				)}
			</LabelFor>
		</Field>
	);
};

export default DayInput;
