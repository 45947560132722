import * as React from "react";

import { Modal } from "@bokio/components/Modal";
import { useUserPreference } from "@bokio/hooks/useUserPreference/useUserPreference";
import { BankLangFactory } from "@bokio/lang";
import { useCompanyInfo } from "@bokio/mobile-web-shared/core/contexts/CompanyInfoContext/CompanyInfoContext";
import * as m from "@bokio/mobile-web-shared/core/model/model";
import { useTodoStatus } from "@bokio/shared/state/requests";

import { useSveaBankIdAuth } from "./useSveaBankIdAuth";

type BankAuthenticationPrompt = m.Bokio.Bank.Contract.Dtos.BankAuthenticationPrompt;
export interface SveaBankIdAuthModalProps {
	controlledOpen?: boolean;
	onSuccess?: (companyId: string) => void;
	onCloseModal: () => void;
	bankIdAuthenticationPrompt?: BankAuthenticationPrompt;
	isOnboarding?: boolean;
}

export const SveaBankIdAuthModalInner: React.FC<SveaBankIdAuthModalProps> = ({
	controlledOpen,
	onSuccess,
	onCloseModal,
	bankIdAuthenticationPrompt,
	isOnboarding,
}) => {
	const { companyInfo, reloadCompanyInfo } = useCompanyInfo();
	const { updateTodoStatus } = useTodoStatus();
	const { refreshPreference } = useUserPreference(companyInfo.Id, m.Entities.UserPreferenceType.BbaKycModalDismissed);
	const bankLang = BankLangFactory();

	const { startWithQr, cancelWithQr, renderModalContent } = useSveaBankIdAuth({
		onSuccess: (company, shouldRefresh) => {
			if (shouldRefresh) {
				refreshPreference();
				reloadCompanyInfo();
				updateTodoStatus();
			}
			onSuccess ? onSuccess(company) : onCloseModal();
		},
		isOnboarding,
	});

	// if controlledOpen is false, the modal should not start auth. In any other case (undefined or true) the modal should start auth
	React.useEffect(() => {
		if (controlledOpen !== false) {
			startWithQr();
		}
		// A bit tricky but here we want to run the function only when controlledOpen changes
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [controlledOpen]);

	return (
		<Modal
			title={bankLang.MirOnboarding_AuthenticateWithBankId}
			visible={controlledOpen ?? !!bankIdAuthenticationPrompt}
			onClose={() => {
				cancelWithQr();
				onCloseModal();
			}}
			testId="SveaBankIdAuthModal_MainView"
			autoFocus={false}
		>
			{renderModalContent()}
		</Modal>
	);
};

export const SveaBankIdAuthModal: React.FC<SveaBankIdAuthModalProps> = props => {
	const { companyInfo } = useCompanyInfo();
	return companyInfo?.Id ? <SveaBankIdAuthModalInner {...props} /> : null;
};
