import { useEffect } from "react";

import { toEnvelope } from "@bokio/mobile-web-shared/core/utils/loaderHelpers";
import { toEnvelopeEndpoint, useApi, useLazyApi } from "@bokio/mobile-web-shared/hooks/useApi/useApi";
import * as proxy from "@bokio/mobile-web-shared/services/api/proxy";
import { success } from "@bokio/mobile-web-shared/services/api/requestState";

import type * as m from "@bokio/mobile-web-shared/core/model/model";

type VariantDto = m.Bokio.Services.Experiments.VariantDto;
const ORIGINAL_VARIANT_NAME = "";

export type Variant = VariantDto;
export interface ExperimentResult {
	/**
	 * The variant to show for this user. If it's undefined and the experiment is enabled it's the original
	 */
	VariantId?: string;
	/**
	 * If the experiment is enabled for this user
	 */
	ExperimentEnabled: boolean;
}

const originalVariant = (enabled: boolean): Variant => {
	return { Name: ORIGINAL_VARIANT_NAME, Index: 0, Enabled: enabled, Id: undefined };
};

const enabledVariant = (variants: VariantDto[]) => variants.find(v => v.Enabled) || originalVariant(true);

export const GetExperiment = async ({ name }: { name: string }): Promise<ExperimentResult> => {
	const response = await proxy.Bokio.ExperimentController.Index.Post(name);
	if (response.Data && response.Data.Enabled) {
		const variant = enabledVariant(response.Data.Variants ?? []);
		return {
			ExperimentEnabled: true,
			VariantId: variant.Id,
		};
	} else {
		return { ExperimentEnabled: false };
	}
};

export const useExperiment = (name: string) => {
	const [request, reload] = useApi(toEnvelopeEndpoint(GetExperiment), [{ name }]);
	return { request, reload };
};

export const useExperimentVariant = (name: string, variantId: string) => {
	const [request] = useApi(toEnvelopeEndpoint(GetExperiment), [{ name }]);
	return { request, hasVariant: request.data?.Data ? request.data.Data.VariantId === variantId : undefined };
};

export const useExperimentIf = (name: string, predicate: boolean) => {
	const [loadExperiment, request] = useLazyApi(toEnvelopeEndpoint(GetExperiment));

	useEffect(() => {
		if (predicate) {
			loadExperiment({ name });
		}
	}, [predicate, name, loadExperiment]);

	if (!predicate) {
		return { request: success(toEnvelope({ ExperimentEnabled: false, VariantId: undefined })) };
	}

	return { request };
};

export const useHasExperimentVariantIf = (experimentName: string, variantId: string, predicate: boolean) => {
	const [loadExperiment, request] = useLazyApi(toEnvelopeEndpoint(GetExperiment));

	useEffect(() => {
		if (predicate) {
			loadExperiment({ name: experimentName });
		}
	}, [predicate, experimentName, loadExperiment]);

	if (!predicate) {
		return { request: success(toEnvelope({ ExperimentEnabled: false, VariantId: undefined })) };
	}

	return { request: request, hasVariant: request.data?.Data?.VariantId === variantId };
};
