import * as React from "react";

import { useLoader } from "@bokio/hooks/useLoader/useLoader";
import { noop } from "@bokio/shared/utils";

import type { Envelope } from "@bokio/mobile-web-shared/core/model/model";
import type { RequestState } from "@bokio/mobile-web-shared/services/api/requestState";

export { toEnvelopeEndpoint, useApiPolling } from "@bokio/mobile-web-shared/hooks/useApi/useApi";
export type { ApiPollingStopConditionType } from "@bokio/mobile-web-shared/hooks/useApi/useApi";

type Endpoint<TParameters extends unknown[], TResult, TError> = (
	...parameters: TParameters
) => Promise<Envelope<TResult, TError>>;
interface Options<TResult, TError, TParameters> {
	onSuccess?: (data: TResult, params: TParameters) => void;
	onError?: (error: TError, errorMessage: string, params: TParameters) => void;
	onApiError?: (error: unknown, params: TParameters) => void;
}

type UseLazyApi<TParameters extends unknown[], TResult, TError> = [
	(...parameters: TParameters) => Promise<Envelope<TResult, TError>>,
	RequestState<Envelope<TResult, TError>>,
	() => void,
];

let debuggingOrder = 0;
let debuggingFunction: (json: string) => Promise<void>;

export const debugApiCallsWith = (fn: (json: string) => Promise<void>) => {
	debuggingFunction = fn;
	debuggingOrder = 0;
};

/**
 * A hook for calling an api endpoint on an action
 *
 *@example
 *const [execute, request, reset] = useLazyApi(
 *	proxy.VerificationController.Create.Post,
 *	{
 *		onSuccess: data => console.log("data from success envelope");
 *		onError: error => console.log("error from error envelope")
 *	}
 *);
 *
 * <Button onClick={() => execute(companyId, verification)} />
 *
 * @param endpoint Enpoint from proxy, must return envelope. (Use toEnvelopeEndpoint if it doesn't).
 * @param options SideEffects which fire on success and on error with proper types from envelope.
 * @deprecated use useLazyApi from mobile-web-shared folder instead
 */
export function useLazyApi<TParameters extends unknown[], TResult, TError>(
	endpoint: Endpoint<TParameters, TResult, TError>,
	options?: Options<TResult, TError, TParameters>,
): UseLazyApi<TParameters, TResult, TError> {
	const optionsRef = React.useRef(options);
	// Hacky way to get the live version of options
	// Not technically correct for all kinds of closure
	// but should cover the most common use case
	optionsRef.current = options;
	// Do the same for the endpoint as well.
	// Since sometimes people assisgn a wrapper endpoint directly
	// where the function's identity changes on every render, unlike the majority of functions in proxy.ts.
	const endpointRef = React.useRef(endpoint);
	endpointRef.current = endpoint;

	const onSuccess = React.useCallback((data, params) => {
		data.Success && optionsRef.current?.onSuccess?.(data.Data, params);
		!data.Success && optionsRef.current?.onError?.(data.Error, data.ErrorMessage, params);
	}, []);
	const onError = React.useCallback((err, params) => {
		optionsRef.current?.onApiError?.(err, params);
	}, []);

	const endpointForLoader = React.useCallback(params => {
		return endpointRef.current(...params);
	}, []);

	const { request, load, reset } = useLoader<TParameters, Envelope<TResult, TError>>({
		endpoint: endpointForLoader,
		onSuccess,
		onError,
	});

	const execute = React.useCallback(
		async (...parameters: TParameters) => {
			let localOrder = 0;
			if (!!debuggingFunction) {
				debuggingOrder += 1;
				localOrder = debuggingOrder;
			}

			if (!!debuggingFunction) {
				await debuggingFunction(JSON.stringify({ debuggingOrder: localOrder, requestParameters: parameters }));
			}

			const response = await load(parameters);

			if (!!debuggingFunction) {
				await debuggingFunction(JSON.stringify({ debuggingOrder: localOrder, response }));
			}

			return response;
		},
		[load],
	);

	return [execute, request, reset];
}

/**
 * A hook for calling an api endpoint as an effect of mounting
 *
 *@example
 *const [request, refresh] = useApi(
 *	proxy.VerificationController.GetAllRows.Get,
 *	[companyInfo.Id],
 *	{
 *		onSuccess: data => console.log("data from success envelope"),
 *		onError: error => console.log("error from error envelope")
 *	}
 *);
 *
 * @param endpoint Endpoint from proxy, must return envelope. (Use toEnvelopeEndpoint if it doesn't).
 * @param parameters An array with the arguments to the enpoint in order of appearance.
 * @param options SideEffects which fire on success and on error with proper types from envelope.
 * @deprecated use useApi from mobile-web-shared folder instead
 */
export function useApi<TParameters extends unknown[], TResult, TError>(
	endpoint: Endpoint<TParameters, TResult, TError>,
	parameters: TParameters,
	options?: Options<TResult, TError, TParameters>,
): [RequestState<Envelope<TResult, TError>>, () => void] {
	const [execute, request] = useLazyApi(endpoint, options);

	const refresh = React.useCallback(() => {
		execute(...parameters).catch(noop);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [execute, JSON.stringify(parameters)]);

	React.useEffect(() => {
		refresh();
	}, [refresh]);

	return [request, refresh];
}
