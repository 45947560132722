import { GeneralLangFactory } from "@bokio/lang";
import * as m from "@bokio/mobile-web-shared/core/model/model";

import type { ToDoCounterType } from "../components/MenuCounter/MenuCounter";
import type { FontelloIcons } from "@bokio/assets/fontello";
import type { AgencyCounterType } from "@bokio/components/Route/AgencyMenuCounter";
import type { BadgeColor } from "@bokio/elements/Badge/Badge";
import type { DeviceQueryResult } from "@bokio/elements/DeviceQuery/useDeviceQuery";
import type { PromoBadgeProps } from "@bokio/elements/PromoBadge/PromoBadge";

type FeatureAvailabilityConfig = m.Config.FeatureAvailabilityConfig;
export interface MenuSection extends MenuAccess {
	icon: FontelloIcons | undefined;
	title: string;
	route: string | undefined;
	subs: MenuSub[];
	counter?: ToDoCounterType | AgencyCounterType;
	badge?: MenuBadgeProps | PromoBadgeProps;
	onClick?: () => void;
	onlyVisibleInMobile?: boolean;
}

interface MenuAccess {
	/**
	 * Or rules between each item in the inner array and And rules between the outer items
	 */
	accessRequirements: string[][];
	featureToggleEnabled?: m.Core.Features;
	featureToggleDisabled?: m.Core.Features;
	featureAvailability?: keyof m.Config.FeatureAvailabilityConfig;
	featureNotAvailable?: keyof m.Config.FeatureAvailabilityConfig;
	paidPlanRequired?: boolean;
	anyOfPartnerCompanyTypes?: m.Entities.PartnerCompanyType[];
	isDivider?: boolean;
	requirePredicate?: () => boolean;
}

export interface MenuSub extends MenuAccess {
	title: string;
	route: string;
	badge?: MenuBadgeProps | PromoBadgeProps;
	onClick?: () => void;
	counter?: ToDoCounterType | AgencyCounterType;
	hidden: boolean;
}

export const hasAccess = <TUserAccess>(access: TUserAccess, accessRequirements: string[][]) => {
	// Slightly complicated. But check that we match all top outer array item (AND) and any of the inner ones (OR)
	const matchedAnds = accessRequirements.filter(requirement => {
		const matchesAnyOrRequirement = requirement.filter(subRequirement => access[subRequirement]).length > 0;
		return matchesAnyOrRequirement;
	}).length;
	return matchedAnds === accessRequirements.length;
};

export const hasPaidPlan = (plan: m.Entities.BokioPlan) => {
	return plan !== m.Entities.BokioPlan.Free;
};

export const isAnyOfPartnerCompanyTypes = (
	partnerCompanyType: m.Entities.PartnerCompanyType | undefined,
	anyOfPartnerCompanyTypes: m.Entities.PartnerCompanyType[] | undefined,
) => {
	if (!anyOfPartnerCompanyTypes) {
		return true;
	}
	return anyOfPartnerCompanyTypes.length
		? partnerCompanyType
			? anyOfPartnerCompanyTypes.includes(partnerCompanyType)
			: false
		: true;
};

export const getProgressBarProps = (endDate: m.Day | undefined, inTrial: boolean) => {
	if (endDate == undefined) {
		return;
	}
	const generalLang = GeneralLangFactory();
	const totalProgressDays = inTrial ? 14 : 31; // GF: These values are hardcoded and represent Trial Length / One Month.

	const today = m.Day.today();
	const earliestStartDate = m.Day.clone(endDate).addDays(-totalProgressDays);

	if (earliestStartDate <= today && today < endDate) {
		const daysRemaining = endDate.dayDiff(today);
		if (inTrial) {
			const progressBarProps = {
				daysRemanining: daysRemaining,
				progressPercentage: 100 - Math.round((daysRemaining / totalProgressDays) * 100),
				heading:
					daysRemaining > 1
						? generalLang.MenuCurrentPlan_XDaysLeftOfTrial
						: generalLang.MenuCurrentPlan_1DayLeftOfTrial,
			};
			return progressBarProps;
		} else {
			const progressBarProps = {
				daysRemanining: daysRemaining,
				progressPercentage: 100 - Math.round((daysRemaining / totalProgressDays) * 100),
				heading:
					daysRemaining > 1
						? generalLang.MenuCurrentPlan_XDaysLeftOfAccess
						: generalLang.MenuCurrentPlan_1DayLeftOfAccess,
			};
			return progressBarProps;
		}
	}
	return null;
};

export const filterMenuSections = <TUserAccess>(
	plan: m.Entities.BokioPlan,
	hasFailedPayments: boolean,
	isTestCompany: boolean,
	featureAvailability: m.Config.FeatureAvailabilityConfig,
	sections: MenuSection[],
	isFeatureEnabled: (feature: m.Core.Features) => boolean,
	deviceQuery: DeviceQueryResult,
	access?: TUserAccess,
	partnerCompanyType?: m.Entities.PartnerCompanyType,
): MenuSection[] => {
	if (!access) {
		return [];
	}

	const hideSubs = (subs: MenuSub[]): MenuSub[] => {
		return subs.length > 1
			? subs
			: subs.map(s => {
					s.hidden = true;
					return s;
				});
	};

	const filterAccess = <T extends MenuAccess>(items: T[]) => {
		return (
			items
				.filter(i => !i.requirePredicate || i.requirePredicate())
				.filter(i => !i.featureToggleEnabled || isFeatureEnabled(i.featureToggleEnabled))
				.filter(i => !i.featureToggleDisabled || !isFeatureEnabled(i.featureToggleDisabled))
				//A bit hackish because not all props are booleans on the featureAvailability
				.filter(i => !i.featureAvailability || featureAvailability[i.featureAvailability] === true)
				.filter(i => !i.featureNotAvailable || featureAvailability[i.featureNotAvailable] === false)
				.filter(i => i.isDivider || hasAccess(access, i.accessRequirements))
				.filter(i => i.isDivider || isAnyOfPartnerCompanyTypes(partnerCompanyType, i.anyOfPartnerCompanyTypes))
				.filter(
					i =>
						!i.isDivider ||
						(hasPaidPlan(plan) && !hasFailedPayments) ||
						isTestCompany ||
						hasAccess(access, [["SupportUser"]]),
				)
				.filter(
					i =>
						!i.paidPlanRequired ||
						(hasPaidPlan(plan) && !hasFailedPayments) ||
						isTestCompany ||
						hasAccess(access, [["SupportUser"]]),
				)
		);
	};

	const filteredSections = filterAccess(sections)
		.map(s => {
			const availableSubs = hideSubs(filterAccess(s.subs));

			if ((s.subs.length && !availableSubs.length) || !hasAccess(access, s.accessRequirements)) {
				return undefined;
			}

			if (s.onlyVisibleInMobile && !deviceQuery.isMobile) {
				return undefined;
			}

			const menuSection: MenuSection = {
				icon: s.icon,
				title: s.title,
				route: s.route,
				counter: s.counter,
				subs: availableSubs,
				badge: s.badge,
				onClick: s.onClick,
				accessRequirements: [],
				isDivider: s.isDivider,
				onlyVisibleInMobile: s.onlyVisibleInMobile,
				requirePredicate: s.requirePredicate,
			};

			return menuSection;
		})
		.filter(s => s !== undefined) as MenuSection[];

	return filteredSections;
};

class MenuAccessBuilder<TUserAccess> implements MenuAccess {
	accessRequirements: string[][] = [];
	featureToggleEnabled?: m.Core.Features;
	featureToggleDisabled?: m.Core.Features;
	featureAvailability?: keyof FeatureAvailabilityConfig;
	featureNotAvailable?: keyof FeatureAvailabilityConfig;
	anyOfPartnerCompanyTypes?: m.Entities.PartnerCompanyType[] = [];
	isDivider?: boolean;
	paidPlanRequired?: boolean;
	requirePredicate?: () => boolean = () => true;

	requireAccess = (...access: (keyof TUserAccess)[]) => {
		this.accessRequirements.push(access.map(x => x.toString()));
		return this;
	};

	requireFeatureToggleEnabled = (featureToggle: m.Core.Features) => {
		this.featureToggleEnabled = featureToggle;
		return this;
	};

	requireFeatureToggleDisabled = (featureToggle: m.Core.Features) => {
		this.featureToggleDisabled = featureToggle;
		return this;
	};

	requireAnyPartnerCompanyType = (...type: m.Entities.PartnerCompanyType[]) => {
		this.anyOfPartnerCompanyTypes = [...(this.anyOfPartnerCompanyTypes || []), ...type];
		return this;
	};

	requireFeatureAvailability = (featureAvailability: keyof FeatureAvailabilityConfig) => {
		this.featureAvailability = featureAvailability;
		return this;
	};

	requireFeatureNotAvailable = (featureNotAvailable: keyof FeatureAvailabilityConfig) => {
		this.featureNotAvailable = featureNotAvailable;
		return this;
	};

	requirePaidPlan = () => {
		this.paidPlanRequired = true;
		return this;
	};

	require = (predicate: () => boolean) => {
		this.requirePredicate = predicate;
		return this;
	};
}

export interface MenuBadgeProps {
	name: string;
	color: BadgeColor;
	showPredicate?: () => boolean;
}

export class SubBuilder<TUserAccess> extends MenuAccessBuilder<TUserAccess> {
	counterType?: ToDoCounterType;
	hidden: boolean;
	badge?: MenuBadgeProps | PromoBadgeProps;
	onClick?: () => void;
	constructor(
		public url: string,
		public title: string,
		public section: SectionBuilder<TUserAccess>,
	) {
		super();
		this.hidden = false;
	}

	addCounter = (counterType: ToDoCounterType) => {
		this.counterType = counterType;
		return this;
	};

	hide = () => {
		this.hidden = true;
		return this;
	};

	addSub = (url: string, title: string) => {
		return this.section.addSub(url, title);
	};
	get addHiddenSub() {
		return (url: string) => this.section.addHiddenSub(url);
	}
	addBadge = (badge: MenuBadgeProps) => {
		if (!badge.showPredicate || badge.showPredicate()) {
			this.badge = badge;
		}
		return this;
	};

	addPromoBadge = (promoBadge: PromoBadgeProps, hidden?: boolean) => {
		if (!hidden) {
			this.badge = promoBadge;
		}
		return this;
	};

	addOnClick = (onClick: () => void) => {
		this.onClick = onClick;
		return this;
	};
}

export class SectionBuilder<TUserAccess> extends MenuAccessBuilder<TUserAccess> {
	sectionUrl?: string;
	subs: SubBuilder<TUserAccess>[] = [];
	counterType?: ToDoCounterType;
	badge?: MenuBadgeProps | PromoBadgeProps;
	onClick?: () => void;
	onlyVisibleInMobile?: boolean;
	constructor(
		public title: string,
		public baseUrl: string,
		public icon?: FontelloIcons,
	) {
		super();
	}

	route = (url: string) => {
		this.sectionUrl = this.baseUrl + url;
		return this;
	};

	addSub = (url: string, title: string) => {
		const s: SubBuilder<TUserAccess> = new SubBuilder<TUserAccess>(this.baseUrl + url, title, this);
		this.subs.push(s);
		return s;
	};

	addHiddenSub = (url: string) => {
		const s: SubBuilder<TUserAccess> = new SubBuilder<TUserAccess>(this.baseUrl + url, url, this);
		s.hide();
		this.subs.push(s);
		return s;
	};

	addCounter = (counterType: ToDoCounterType) => {
		this.counterType = counterType;
		return this;
	};

	addBadge = (badge: MenuBadgeProps) => {
		if (!badge.showPredicate || badge.showPredicate()) {
			this.badge = badge;
		}
		return this;
	};

	addPromoBadge = (promoBadge: PromoBadgeProps) => {
		this.badge = promoBadge;
		return this;
	};

	addOnClick = (onClick: () => void) => {
		this.onClick = onClick;
		return this;
	};

	showOnlyInMobile = () => {
		this.onlyVisibleInMobile = true;
		return this;
	};
}

export class MenuBuilder<TUserAccess> {
	sections: SectionBuilder<TUserAccess>[] = [];

	constructor(public baseUrl: string) {}

	addSection = (icon: FontelloIcons, title: string) => {
		const s = new SectionBuilder<TUserAccess>(title, this.baseUrl, icon);
		this.sections.push(s);
		return s;
	};

	addDivider = () => {
		const s = new SectionBuilder<TUserAccess>("", "");
		s.isDivider = true;
		this.sections.push(s);
	};

	build = (): MenuSection[] => {
		return this.sections.map(
			(s): MenuSection => ({
				icon: s.icon,
				title: s.title,
				route: s.sectionUrl,
				counter: s.counterType,
				accessRequirements: s.accessRequirements,
				featureToggleEnabled: s.featureToggleEnabled,
				featureToggleDisabled: s.featureToggleDisabled,
				featureAvailability: s.featureAvailability,
				featureNotAvailable: s.featureNotAvailable,
				anyOfPartnerCompanyTypes: s.anyOfPartnerCompanyTypes,
				badge: s.badge,
				onClick: s.onClick,
				isDivider: s.isDivider,
				onlyVisibleInMobile: s.onlyVisibleInMobile,
				requirePredicate: s.requirePredicate,
				paidPlanRequired: s.paidPlanRequired,
				subs: s.subs.map(sub => ({
					title: sub.title,
					route: sub.url,
					accessRequirements: sub.accessRequirements,
					featureToggleEnabled: sub.featureToggleEnabled,
					featureToggleDisabled: sub.featureToggleDisabled,
					featureAvailability: sub.featureAvailability,
					featureNotAvailable: sub.featureNotAvailable,
					anyOfPartnerCompanyTypes: sub.anyOfPartnerCompanyTypes,
					counter: sub.counterType,
					badge: sub.badge,
					onClick: sub.onClick,
					hidden: sub.hidden,
					requirePredicate: sub.requirePredicate,
					paidPlanRequired: sub.paidPlanRequired,
				})),
			}),
		);
	};
}
