import { usePaymentContext } from "@bokio/contexts/PaymentContext/PaymentContext";
import { useFeatureAvailability } from "@bokio/hooks/useFeatureAvailability/useFeatureAvailability";
import { useFeatureToggle } from "@bokio/hooks/useFeatureToggle/useFeatureToggle";
import {
	AccountingLangFactory,
	BankLangFactory,
	ExpensesLangFactory,
	GeneralLangFactory,
	InvoicesLangFactory,
	SalaryLangFactory,
	SettingsLangFactory,
} from "@bokio/lang";
import * as m from "@bokio/mobile-web-shared/core/model/model";
import { useActiveBankConnection } from "@bokio/mobile-web-shared/hooks/useActiveBankConnection/useActiveBankConnection";
import { useApi } from "@bokio/mobile-web-shared/hooks/useApi/useApi";
import * as proxy from "@bokio/mobile-web-shared/services/api/proxy";
import { getRoute } from "@bokio/shared/route";
import { useUser } from "@bokio/shared/state/requests";

import { SettingsBuilder } from "./settingsHelper";

import type { SettingsBuilderSection } from "./settingsHelper";

import CompanyUserRoleType = m.Entities.CompanyUserRoleType;
import CompanyType = m.Entities.CompanyType;

interface useSettingsReturn {
	settings: SettingsBuilderSection[];
	isLoading: boolean;
}

export const useSettings = (
	company: m.Core.CompanyInfo,
	access?: m.Settings.Controllers.UserAccess,
): useSettingsReturn => {
	const featureAvailability = useFeatureAvailability();
	const { isFeatureEnabled } = useFeatureToggle();
	const { currentUserPermission } = usePaymentContext();
	const {
		hasActiveBusinessAccount,
		activeBusinessAccountExternalSystem,
		activeBusinessAccountExternalSystemHasConsent,
		hasSveaNonConsentCompanyWithEmptyBankAccount,
	} = useActiveBankConnection();

	const { user } = useUser();

	const generalLang = GeneralLangFactory();
	const lang = SettingsLangFactory();
	const accountingLang = AccountingLangFactory();
	const salaryLang = SalaryLangFactory();
	const expensesLang = ExpensesLangFactory();
	const bankLang = BankLangFactory();
	const invoiceLang = InvoicesLangFactory();

	const [getRoles] = useApi(proxy.Settings.UserController.GetUserRoles.Get, [company.Id]);
	const roles = getRoles.data?.Data;

	if (getRoles.isLoading) {
		return { settings: [], isLoading: true };
	}

	if (!access || !roles || !user) {
		return { settings: [], isLoading: false };
	}

	const isOwner = roles.CompanyUserRoles.find(r => r.Type == CompanyUserRoleType.AppCompanyOwner)?.UserId == user.Id;
	const isSupportUser = access.SupportUser;
	const isTestCompany = company.CompanySystem === m.Entities.CompanySystem.TestCompany;

	const blockDueToFailedPayments =
		company.BillingPaymentState === m.Entities.BillingSubscriptionPaymentState.BlockedDueToMissingPayment;

	const hasPaidPlan =
		(company.Plan !== m.Entities.BokioPlan.Free && !blockDueToFailedPayments) || isSupportUser || isTestCompany;

	const hasSvea = activeBusinessAccountExternalSystem === m.Entities.Bank.ExternalSystem.Svea;
	const hasAccessToOffboarding = currentUserPermission.CanSignPayment && access.UploadBank;
	const directorsPayrollEnabled = company?.CompanyType == CompanyType.LimitedCompany;
	const salarySettingsAccess = featureAvailability.Salary || directorsPayrollEnabled ? access.SalarySettings : false;

	const shouldShowCustomTemplates =
		company?.Country !== m.CountryCode.GB || isFeatureEnabled(m.Core.Features.UkCustomTemplates);

	const settings = new SettingsBuilder();
	const companyIdParams = { company: company.Id };

	settings
		.addSection(generalLang.Company, "companySettings")
		.requireAccess(access.CompanySettings || access.AdminUsers)

		.addItem(
			lang.Billing,
			[lang.Billing],
			getRoute("billing", companyIdParams) + "?from=settings",
			lang.Billing_SubText_Text,
		)
		.requireAccess(access.CompanySettings && featureAvailability.Billing)

		.addItem(
			lang.Company_PageTitle,
			[lang.Company_PageTitle],
			getRoute("companySettings", companyIdParams),
			lang.Overview_CompanySettings_SubText_text,
		)
		.requireAccess(access.CompanySettings)

		.addItem(lang.Layout_PageTitle, [lang.Layout_PageTitle], getRoute("layoutLogoColor", companyIdParams))
		.requireAccess(hasPaidPlan && access.CompanySettings)

		.addItem(generalLang.Users, [generalLang.Users], getRoute("users", companyIdParams))
		.requireAccess(hasPaidPlan && access.AdminUsers)

		.addItem(lang.ManageNotifications, [lang.ManageNotifications], getRoute("notificationSettings", companyIdParams))
		.requireAccess(hasPaidPlan && access.CompanySettings)

		.addItem(lang.FeatureToggles, [lang.FeatureToggles], getRoute("featureToggles", companyIdParams))
		.requireAccess(hasPaidPlan && access.CompanySettings)

		.addItem(lang.AuditHistory_Title, [lang.AuditHistory_Title], getRoute("auditHistory", companyIdParams))
		.requireAccess(hasPaidPlan && access.AdminUsers);

	settings
		.addSection(generalLang.AccountingApplication, "accountingSettings")
		.requireAccess(hasPaidPlan && (access.AccountingSettings || access.Partner || access.Inbox))
		.addItem(
			accountingLang.FiscalYears,
			[accountingLang.FiscalYears],
			getRoute("fiscalList", companyIdParams),
			lang.Overview_FiscalYears_SubText_text,
		)
		.requireAccess(access.CompanySettings)

		.addItem(
			accountingLang.LockPeriod,
			[accountingLang.LockPeriod],
			getRoute("lockPeriod", companyIdParams),
			lang.Overview_LockPeriod_SubText_text,
			"Settings_AccountingSettings_ListItem_LockPeriod",
			true,
		)
		.requireAccess(access.AccountingSettings)

		.addItem(
			accountingLang.PaymentAccounts,
			[accountingLang.PaymentAccounts],
			getRoute("moneyAccounts", companyIdParams),
			lang.Overview_MoneyAccounts_SubText_text,
		)
		.requireAccess(access.AccountingSettings)

		.addItem(
			accountingLang.AutomationRules,
			[accountingLang.AutomationRules],
			getRoute("automationRules", companyIdParams),
			lang.Overview_AutomationRules_SubText_text,
		)
		.requireAccess(access.CompanySettings && access.Bookkeep)

		.addItem(
			accountingLang.CustomBookkeepingTemplates,
			[accountingLang.CustomBookkeepingTemplates],
			getRoute("customTemplates", companyIdParams),
			lang.Overview_CustomBookkeepingTemplates_SubText_text,
		)
		.requireAccess(access.CompanySettings && shouldShowCustomTemplates)

		.addItem(lang.AccountPlan_Title, [lang.AccountPlan_Title], getRoute("accountPlan", companyIdParams))
		.requireAccess(access.AccountingSettings)

		.addItem(
			lang.Overview_ExportBookkeeping,
			[lang.Overview_ExportBookkeeping, generalLang.Sie],
			getRoute("exportBookkeeping", companyIdParams),
		)
		.requireAccess(access.AccountingSettings || access.Partner)

		.addItem(generalLang.Inbox, [generalLang.Inbox], undefined, lang.Overview_Inbox_SubText_text)
		.setExternalRoute(`/${company.Id}/Settings/Inbox`)
		.requireAccess(access.Inbox)

		.addItem(
			lang.TagTypes_Title,
			[lang.TagTypes_Title, lang.TagTypes_SubTitle],
			getRoute("tagTypes", companyIdParams),
			lang.TagTypes_SubTitle,
		)
		.requireAccess(access.AccountingSettings)

		.addItem(lang.RemoveImport_Menu, [lang.RemoveImport_Menu], getRoute("removeImport", companyIdParams))
		.requireAccess(access.AccountingSettings);

	settings
		.addSection(generalLang.Invoices, "invoiceSettings")
		.requireAccess(hasPaidPlan && access.InvoiceSettings)
		.addItem(
			lang.InvoiceSettings_Heading,
			[lang.InvoiceSettings_Heading],
			getRoute("invoiceSettings", companyIdParams),
			lang.InvoiceSettings_Description,
			"Settings_InvoiceSettings_ListItem_InvoiceSettings",
		)
		.requireAccess(access.InvoiceSettings)

		.addItem(
			lang.ConfigureEInvoice,
			[lang.ConfigureEInvoice],
			getRoute("eInvoiceSettings", companyIdParams),
			lang.EInvoiceSettings_Description,
			"Settings_InvoiceSettings_ConfigureEInvoice",
		)
		.requireAccess(featureAvailability.EInvoice && access.InvoiceSettings && access.CompanySettings)

		.addItem(
			lang.AutomaticCheckingSettings_Heading,
			[lang.AutomaticCheckingSettings_Heading],
			getRoute("automaticCheckingSettings", companyIdParams),
			lang.AutomaticCheckingSettings_Description,
			"Settings_InvoiceSettings_ListItem_AutomaticCheckingSettings",
		)
		.requireAccess(access.InvoiceSettings)

		.addItem(
			lang.PaymentMethodSettings_Heading,
			[
				lang.PaymentMethodSettings_Heading,
				invoiceLang.PaymentMethod_Bankgiro,
				invoiceLang.PaymentMethod_Plusgiro,
				invoiceLang.PaymentMethod_Swish,
				invoiceLang.PaymentMethod_Paypal,
				invoiceLang.PaymentMethod_IBAN,
			],
			getRoute("paymentMethodSettings", companyIdParams),
			lang.PaymentMethodSettings_Description,
			"Settings_InvoiceSettings_ListItem_PaymentMethodSettings",
		)
		.requireAccess(access.InvoiceSettings && access.CompanySettings);

	settings
		.addSection(generalLang.SalaryApplication, "salarySettings")
		.requireAccess(hasPaidPlan && salarySettingsAccess)
		.addItem(
			lang.Overview_Salary_ContactPerson,
			[lang.Overview_Salary_ContactPerson],
			getRoute("salarySettings", {
				company: company.Id,
				returnUrl: getRoute("settingsOverview", { company: company.Id }),
			}),
		)
		.requireAccess(featureAvailability.Salary && salarySettingsAccess)

		.addItem(
			lang.CompanyVacationSettings_Title,
			[lang.CompanyVacationSettings_Title],
			getRoute("companyVacationSettings", companyIdParams),
		)
		.requireAccess(featureAvailability.Salary && salarySettingsAccess)

		.addItem(lang.PlaceOfEmployment, [lang.PlaceOfEmployment], getRoute("placeOfEmploymentSettings", companyIdParams))
		.requireAccess(featureAvailability.Salary && salarySettingsAccess)

		.addItem(
			salaryLang.EmployerDetails,
			[salaryLang.EmployerDetails],
			getRoute(
				"salarySettingUK",
				{ company: company.Id },
				{ returnUrl: getRoute("settingsOverview", { company: company.Id }) },
			),
			undefined,
			"salarySettingsUK_Item",
		)
		.requireAccess(company.Country == m.CountryCode.GB && salarySettingsAccess);

	settings
		.addSection(generalLang.ExpenseClaims, "expensesSettings")
		.requireAccess(hasPaidPlan && access.Disbursements)
		.addItem(
			expensesLang.ExpenseSettingsTitle,
			[expensesLang.ExpenseSettingsTitle],
			//We keep the expense settings menu for now but redirect to
			// the new lock period page. We should remove this menu item
			// when the new lock period becomes new default
			getRoute("lockPeriod", companyIdParams),
		)
		.requireAccess(access.Disbursements)

		.addItem(
			expensesLang.CompanyCards_Title,
			[expensesLang.CompanyCards_Title],
			getRoute("companyCards", companyIdParams),
		)
		.requireAccess(access.Disbursements);

	settings
		.addSection(bankLang.BokioBusinessAccount, "bbaSettings")
		.requireAccess((hasPaidPlan && hasActiveBusinessAccount) || hasSveaNonConsentCompanyWithEmptyBankAccount)

		.addItem(
			bankLang.SveaKycApplication_Settings_Title,
			[bankLang.SveaKycApplication_Settings_Title],
			getRoute("sveaKycScene", companyIdParams),
			bankLang.SveaKycApplication_Settings_Subtitle,
		)
		.requireAccess(hasSvea && currentUserPermission.CanSignPayment)

		.addItem(
			bankLang.TerminateAccount,
			[bankLang.TerminateAccount],
			getRoute("bokioBusinessAccountOffboarding", companyIdParams),
			lang.Overview_TerminateBokioBusinessAccount_Description,
		)
		.requireAccess(hasAccessToOffboarding)

		.addItem(bankLang.SveaConsent, [bankLang.SveaConsent], getRoute("sveaBankConsent", companyIdParams))
		.requireAccess(
			(hasSvea && !!activeBusinessAccountExternalSystemHasConsent && currentUserPermission.CanSignPayment) ||
				hasSveaNonConsentCompanyWithEmptyBankAccount,
		);

	settings
		.addSection(generalLang.Import, "importSettings")
		.requireAccess(hasPaidPlan && (access.AccountingSettings || access.Partner))
		.addItem(
			generalLang.Bookkeeping,
			[generalLang.Bookkeeping],
			getRoute("settingsImportOverview", companyIdParams),
			lang.Overview_Import_Bookkeeping_Description,
		)
		.requireAccess(company.Country === m.CountryCode.SE && access.AccountingSettings)

		.addItem(
			lang.OpeningBalances_Title,
			[lang.OpeningBalances_Title],
			getRoute("openingBalances", companyIdParams),
			lang.Overview_OpeningBalances,
			"OpeningBalancesSettings_Link",
		)
		.requireAccess(company.Country !== m.CountryCode.SE && access.AccountingSettings)

		.addItem(
			lang.Overview_Import_TradeDebtors,
			[lang.Overview_Import_TradeDebtors],
			getRoute("accountReceivables", companyIdParams),
			lang.Overview_Import_TradeDebtors_Description,
		)
		.requireAccess(access.AccountingSettings)

		.addItem(
			lang.Overview_Import_TradeCreditors,
			[lang.Overview_Import_TradeCreditors],
			getRoute("accountPayables", companyIdParams),
			lang.Overview_Import_TradeCreditors_Description,
		)
		.requireAccess(access.AccountingSettings);

	settings
		.addSection(generalLang.YourAccount, "accountSettings")
		.requireAccess(true)
		.addItem(
			generalLang.PersonalSettings,
			[generalLang.PersonalSettings],
			getRoute("personalSettings", { returnUrl: window.location.pathname }),
		)
		.requireAccess(true)

		.addItem(
			lang.Overview_ChangePassword,
			[lang.Overview_ChangePassword],
			getRoute("changePassword", undefined, { returnUrl: window.location.pathname }),
		)
		.requireAccess(hasPaidPlan && true)

		.addItem(lang.UpdateUserName, [lang.UpdateUserName], getRoute("updateUsername"))
		.requireAccess(hasPaidPlan && true)

		.addItem(
			lang.BankId_Title,
			[lang.BankId_Title],
			getRoute("bankidSettings", { returnUrl: window.location.pathname }),
		)
		.requireAccess(hasPaidPlan && true)

		.addItem(lang.TOTP_Title, [lang.TOTP_Title], getRoute("totpSettings", { returnUrl: window.location.pathname }))
		.requireAccess(true)

		.addItem(
			lang.Security_RecentLogin_Title,
			[lang.Security_RecentLogin_Title],
			getRoute("recentLogins", { returnUrl: window.location.pathname }),
		)
		.requireAccess(true)

		.addItem(
			lang.Security_LogOut_Everywhere_Title,
			[lang.Security_LogOut_Everywhere_Title],
			getRoute("logOutEverywhere", { returnUrl: window.location.pathname }),
		)
		.requireAccess(true)

		.addItem(
			lang.Overview_ExportCompanyData,
			[lang.Overview_ExportCompanyData],
			getRoute("exportData", companyIdParams),
		)
		.requireAccess(
			hasPaidPlan &&
				(access.AccountingSettings || access.InvoiceSettings || (featureAvailability.Salary && access.SalarySettings)),
		)

		.addItem(lang.LeaveCompany_heading, [lang.LeaveCompany_heading], getRoute("leaveCompany", companyIdParams))
		.requireAccess(true)

		.addItem(lang.DeleteCompany_heading, [lang.DeleteCompany_heading], getRoute("deleteCompany", companyIdParams))
		.requireAccess(isOwner);

	return { settings: settings.build(), isLoading: false };
};
