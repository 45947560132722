import { AccountingLangFactory, InvoicesLangFactory } from "@bokio/lang";
import { CountryCode, Day } from "@bokio/mobile-web-shared/core/model/model";
import {
	getSupportedLocaleFormats,
	getSupportedLocaleRegex,
	parseDate,
} from "@bokio/mobile-web-shared/core/model/types";
import { parseNumber } from "@bokio/mobile-web-shared/core/utils/numberUtils";
import { BankAccountValidator } from "@bokio/mobile-web-shared/SharedRedLibrary/validators/src/validators/bank-account/bank-account-validator";
import { IBAN } from "@bokio/mobile-web-shared/SharedRedLibrary/validators/src/validators/IBAN/IBAN";
import { GiroTypes, PGorBG } from "@bokio/mobile-web-shared/SharedRedLibrary/validators/src/validators/PGorBG/PGorBG";
import { containsNoWhitespace, isAlphanumeric, toString } from "@bokio/shared/utils";
import { formatDate, formatMessage } from "@bokio/shared/utils/format";
import { checkEUVATNumberFormat, getVatErrorMessage } from "@bokio/shared/utils/vatCountryCodeHelper";
import { validateNumberWithLuhnChecksum } from "@bokio/utils/luhn";

import type { CountryInfoDto, CurrencyInfoDto } from "@bokio/contexts/PaymentContext/PaymentContext";
import type { GeneralLang } from "@bokio/lang/types/GeneralLang";
import type * as m from "@bokio/mobile-web-shared/core/model/model";
import type { RangeModifier } from "react-day-picker";

type AccountOption = m.Settings.Viewmodels.AccountOption;
export enum FieldRuleLevel {
	Warning,
	MustFixBeforeSend,
	MustFixNow,
}

export enum FieldRuleType {
	NotSpecified,
	Required,
	GreaterThan,
	GreaterThanOrEqual,
	NonZero,
	Zero,
	SmallerThanOrEqual,
	DateGreaterThanOrEqual,
	DateLessThanOrEqual,
	DateIsNotOneOf,
	DateLessThan,
	OrgNumber,
	Email,
	LocaleDate,
	DateExists,
	PersonNumber,
	ConfirmationCode,
	NotEqualTo,
	MinLength,
	MaxLength,
	RangeLength,
	Equal,
	VatNumberFormat,
	NotInList,
	AtLeastOneInList,
	BankMustMatchOrConsultation,
	BankgiroNumber,
	PlusgiroNumber,
	SeBankClearingNumber,
	SeBankAccountNumber,
	Iban,
	Bic,
	OcrNumber,
	PhoneNumber,
	NotContainExternalLink,
	NotContainOnlySpaces,
	SupportedCurrency,
	Alphanumeric,
	NoWhitespace,
	NINO,
	EmployerPAYE,
	AccountsOfficeReference,
	UKTaxCode,
	AccountNumber,
	CountryNotSupported,
}

export class FieldRule {
	constructor(
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		public validateFunction: (value: any, item: any, canFixLater: boolean) => boolean,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		public actionCreator: (value: any, item: any, canFixLater: boolean) => string,
		public fieldName: string,
		public level: FieldRuleLevel,
		// TODO AP 2024-04: this appears to be unused - remove
		public type: FieldRuleType,
	) {}

	// AP 2024-04 - reminder to self: please fix this disgusting code in a crazy friday, we deserve better than this garbage.
	/* TODO list:
	- remove `canFixLater` (unused)
	- type generics somehow, should be doable
	- dig into react-hook-form. There has to be a way that this is avoidable */
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	validate = (value: any, item: any = undefined, canFixLater = false): RuleValidationResult => {
		const isValid = this.validateFunction(value, item, canFixLater);
		return { isError: !isValid, action: this.actionCreator(value, item, canFixLater), rule: this };
	};
}

// AP 2024-04. this is temporary. Once I make the validation API better, this function probably won't be needed anymore
/** Generic rule factory that allows to provide a test function and an error message */
export function createStringRule(
	testFunc: (v: string) => boolean,
	message: string,
	fieldName = "",
	level = FieldRuleLevel.MustFixNow,
	type = FieldRuleType.Required,
) {
	return new FieldRule(
		v => !v || (typeof v === "string" && testFunc(v)),
		() => message,
		fieldName,
		level,
		type,
	);
}

export interface RuleValidationResult {
	isError: boolean;
	action: string;
	rule: FieldRule;
}

export interface FieldValidationResult {
	key: string;
	errors: RuleValidationResult[];
}

export class FieldRuleFactory {
	constructor(protected lang: GeneralLang) {}

	Required(
		fieldName: string,
		level: FieldRuleLevel = FieldRuleLevel.Warning,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		validator?: (v: any) => boolean,
	): FieldRule {
		return new FieldRule(
			v => (validator ? validator(v) : Array.isArray(v) ? v.length > 0 : v || typeof v === "number"),
			canFixLater =>
				!canFixLater || level === FieldRuleLevel.MustFixNow
					? this.lang.Validation_Action_DoNow_Required
					: this.lang.Validation_Action_DoLater_Required,
			fieldName,
			level,
			FieldRuleType.Required,
		);
	}

	Boolean(fieldName: string, level: FieldRuleLevel = FieldRuleLevel.Warning) {
		return this.Required(fieldName, level, v => typeof v === "boolean");
	}

	PositiveInteger(fieldName: string, level: FieldRuleLevel = FieldRuleLevel.Warning, allowUndefined = true) {
		return new FieldRule(
			// eslint-disable-next-line deprecate/function
			v => (typeof v === "number" || v ? /^\d+$/.test(toString(v)) : allowUndefined),
			() => this.lang.FieldMustBePositiveInteger,
			fieldName,
			level,
			FieldRuleType.Required,
		);
	}

	BeNumber(fieldName: string, level: FieldRuleLevel = FieldRuleLevel.Warning, allowUndefined = true) {
		return new FieldRule(
			v => {
				return typeof v === "number" || (v ? !isNaN(parseNumber(v)) : allowUndefined);
			},
			() => this.lang.FieldMustBeNumber,
			fieldName,
			level,
			FieldRuleType.Required,
		);
	}

	GreaterThan(
		fieldName: string,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		lowerLimit: number | ((v: any, item: any) => number),
		level: FieldRuleLevel = FieldRuleLevel.Warning,
	): FieldRule {
		return new FieldRule(
			(v, item) => {
				if (!v && v !== 0) {
					return true;
				}
				const lowerNumber = typeof lowerLimit == "function" ? lowerLimit(v, item) : lowerLimit;
				return (typeof v === "string" ? parseNumber(v) : v) > lowerNumber;
			},
			(v, item) => {
				const lowerNumber = typeof lowerLimit == "function" ? lowerLimit(v, item) : lowerLimit;
				return formatMessage(this.lang.Validation_GreaterThan_Must, lowerNumber.toString());
			},
			fieldName,
			level,
			FieldRuleType.GreaterThan,
		);
	}

	GreaterThanOrEqual(fieldName: string, lowerLimit: number, level: FieldRuleLevel = FieldRuleLevel.Warning): FieldRule {
		return new FieldRule(
			v => {
				if (!v && v !== 0) {
					return true;
				}
				return (typeof v === "string" ? parseNumber(v) : v) >= lowerLimit;
			},
			() => formatMessage(this.lang.Validation_GreaterThanOrEqual_Must, lowerLimit.toString()),
			fieldName,
			level,
			FieldRuleType.GreaterThanOrEqual,
		);
	}

	Equal(
		fieldName: string,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		comparator: string | number | ((item: any) => boolean),
		level: FieldRuleLevel = FieldRuleLevel.Warning,
		displayValue?: string | number,
		messageShouldBeFormatted = true,
	): FieldRule {
		return new FieldRule(
			v => {
				if (v == null) {
					return true;
				}
				if (typeof comparator === "function") {
					return comparator(v);
				}
				return v === comparator;
			},
			() => {
				if (messageShouldBeFormatted) {
					// eslint-disable-next-line deprecate/function
					return formatMessage(this.lang.Validation_Equal, toString(displayValue || comparator));
				}

				// eslint-disable-next-line deprecate/function
				return toString(displayValue || comparator);
			},
			fieldName,
			level,
			FieldRuleType.Equal,
		);
	}

	NonZero(fieldName: string, level: FieldRuleLevel = FieldRuleLevel.Warning): FieldRule {
		return new FieldRule(
			v => {
				if (!v && v !== 0) {
					return true;
				}
				return (typeof v === "string" ? parseNumber(v) : v) !== 0;
			},
			() => formatMessage(this.lang.Validation_NonZero_Must),
			fieldName,
			level,
			FieldRuleType.NonZero,
		);
	}

	Zero(fieldName: string, level: FieldRuleLevel = FieldRuleLevel.Warning): FieldRule {
		return new FieldRule(
			v => {
				if (!v && v !== 0) {
					return true;
				}
				return (typeof v === "string" ? parseNumber(v) : v) === 0;
			},
			() => formatMessage(this.lang.Validation_Zero_Must),
			fieldName,
			level,
			FieldRuleType.NonZero,
		);
	}

	SmallerThanOrEqual(fieldName: string, upperLimit: number, level: FieldRuleLevel = FieldRuleLevel.Warning): FieldRule {
		return new FieldRule(
			v => {
				if (!v) {
					return true;
				}
				return (typeof v === "string" ? parseNumber(v) : v) <= upperLimit;
			},
			() => formatMessage(this.lang.Validation_SmallerThanOrEqual_Must, upperLimit.toString()),
			fieldName,
			level,
			FieldRuleType.SmallerThanOrEqual,
		);
	}

	OrgNumber(
		fieldName: string,
		country: CountryCode,
		level: FieldRuleLevel = FieldRuleLevel.MustFixBeforeSend,
	): FieldRule {
		return new FieldRule(
			v => {
				if (!v) {
					return true;
				}
				if (country === CountryCode.SE) {
					const regex = /^\d{6}[-]?\d{4}$/;
					return regex.test(v);
				}
				// TODO: Implement UK pattern here when ready https://www.informdirect.co.uk/wp-content/uploads/2015/10/Company-number-examples.png
				return true;
			},
			() => `${this.lang.ShouldBeOfTheFormat} xxxxxxxxxx ${this.lang._OR} xxxxxx-xxxx`,
			fieldName,
			level,
			FieldRuleType.OrgNumber,
		);
	}

	OrgNumberHasCorrectCheckSum(fieldName: string, level: FieldRuleLevel = FieldRuleLevel.MustFixBeforeSend): FieldRule {
		return new FieldRule(
			v => {
				if (!v) {
					return true;
				}
				return validateNumberWithLuhnChecksum(v);
			},
			() => this.lang.Validation_OrgNumber_NotValid,
			fieldName,
			level,
			FieldRuleType.OrgNumber,
		);
	}

	VATNumberCheckFormat(fieldName: string, country: string, level: FieldRuleLevel): FieldRule {
		return new FieldRule(
			v => {
				if (!v || v === "") {
					return true;
				}
				return checkEUVATNumberFormat(v, country);
			},
			() => getVatErrorMessage(country),
			fieldName,
			level,
			FieldRuleType.VatNumberFormat,
		);
	}

	Email(fieldName: string, level: FieldRuleLevel = FieldRuleLevel.MustFixBeforeSend): FieldRule {
		return new FieldRule(
			v => {
				if (!v) {
					return true;
				}
				// We have the same regex in (EmailValidator.cs). Always update both.
				const regex = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/;
				return regex.test(v);
			},
			() => this.lang.Validation_ValidEmail,
			fieldName,
			level,
			FieldRuleType.Email,
		);
	}

	/**
	 * Validates UK National Insurance Number is in the format AB123456C
	 */
	NINO(fieldName: string, level: FieldRuleLevel = FieldRuleLevel.MustFixBeforeSend): FieldRule {
		return new FieldRule(
			v => {
				if (!v) {
					return true;
				}
				const regex = /^[a-zA-Z]{2}[0-9]{6}[a-zA-Z]{1}$/;
				return regex.test(v);
			},
			() => this.lang.DirectorSetup_NINOValidationError,
			fieldName,
			level,
			FieldRuleType.NINO,
		);
	}

	/**
	 * Validates UK employer PAYE reference is in the format 123/A45678
	 */
	EmployerPAYE(fieldName: string, level: FieldRuleLevel = FieldRuleLevel.MustFixBeforeSend): FieldRule {
		const testCompanyPaye = "635/A635";
		return new FieldRule(
			v => {
				if (!v) {
					return true;
				}
				const regex = /^[0-9]{3}\/[a-zA-Z0-9]{5,7}$/;
				return regex.test(v) || v == testCompanyPaye;
			},
			() => this.lang.DirectorSetup_EmployerPayeValidationError,
			fieldName,
			level,
			FieldRuleType.EmployerPAYE,
		);
	}

	/**
	 * Validates UK Accounts Office Reference is in the format 123AB12345678
	 */
	AccountsOfficeReference(fieldName: string, level: FieldRuleLevel = FieldRuleLevel.MustFixBeforeSend): FieldRule {
		return new FieldRule(
			v => {
				if (!v) {
					return true;
				}
				const regex = /^[0-9]{3}[a-zA-Z]{2}[0-9]{8}$/;
				return regex.test(v);
			},
			() => this.lang.DirectorSetup_AccountsOfficeReferenceValidationError,
			fieldName,
			level,
			FieldRuleType.AccountsOfficeReference,
		);
	}

	UKTaxCode(fieldName: string, level: FieldRuleLevel = FieldRuleLevel.MustFixBeforeSend): FieldRule {
		let message = this.lang.DirectorSetup_TaxCodeValidationError;
		const validTaxCodes = [/^[SC]?[0-9]{1,6}[LMNT]$/, /^C?[D](0|1)$/, /^[SC]?BR$/, /^NT$/, /^SD[0-2]{1}$/, /^[SC]?0T$/];

		const bokioSupportedTaxCodes = [/^[SC]?[0-9]{1,6}[L]$/, /^BR$/];

		return new FieldRule(
			v => {
				if (!v) {
					return true;
				}

				if (bokioSupportedTaxCodes.some(t => v.match(new RegExp(t, "i")))) {
					return true;
				}
				if (validTaxCodes.some(t => v.match(new RegExp(t, "i")))) {
					message = this.lang.DirectorSetup_TaxCodeNotSupportedError;
					return false;
				}
				return false;
			},
			() => message,
			fieldName,
			level,
			FieldRuleType.UKTaxCode,
		);
	}

	// NOTE: MQ 2020-01-10 To reduce ambiguous formats, we only allow truncated year component
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	private isValidDateFormat(input: any) {
		// eslint-disable-next-line deprecate/function
		const value = input instanceof Day ? input.getRawValue() : toString(input);
		return getSupportedLocaleRegex(true).test(value);
	}
	/**
	 * This rule depends on `Required` and should be placed after `Required` to get the correct error message.
	 * For example: `[factory.Required, factory.DateFormat]`
	 * Only validate if value's type is string or Day
	 * */
	DateFormat(fieldName: string, level: FieldRuleLevel = FieldRuleLevel.MustFixBeforeSend): FieldRule {
		return new FieldRule(
			v => v == null || this.isValidDateFormat(v),
			() => this.lang.ShouldBeOfTheFormat + ` ${getSupportedLocaleFormats(true).join(", ")}`,
			fieldName,
			level,
			FieldRuleType.LocaleDate,
		);
	}

	/**
	 * This rule depends on `DateFormat` and should be placed after `DateFormat` to get the correct error message.
	 * For example: `[factory.Required, factory.DateFormat, factory.DateExists]`
	 * Only validate if an input is in valid format.
	 */
	DateExists(fieldName: string, level: FieldRuleLevel = FieldRuleLevel.MustFixBeforeSend): FieldRule {
		return new FieldRule(
			v => {
				if (v instanceof Date && !isNaN(v.getTime())) {
					return true;
				}
				if (this.isValidDateFormat(v)) {
					// eslint-disable-next-line deprecate/function
					const parsed = v instanceof Day ? v : parseDate(toString(v));
					return !isNaN(parsed.getTime());
				} else {
					return true;
				}
			},
			() => this.lang.Validation_NonExistingDate,
			fieldName,
			level,
			FieldRuleType.DateExists,
		);
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	private isValidDateCompare(input: any, compareFnc: (d1: Date | Day, d2: Date | Day) => boolean, bound?: Date | Day) {
		if (!input || !bound || ((bound instanceof Date || bound instanceof Day) && isNaN(bound.getTime()))) {
			return true;
		}
		// eslint-disable-next-line deprecate/function
		const parsed = input instanceof Day ? input : parseDate(toString(input));
		return isNaN(parsed.getTime()) || compareFnc(parsed, bound);
	}
	/**
	 * This rule depends on `DateExists` and should be placed after `DateExists` to get the correct error message.
	 * For example: `[factory.Required, factory.DateFormat, factory.DateExists, factory.DateGreaterThanOrEqual]`
	 * Only validate if an input is an exist date
	 */
	DateGreaterThanOrEqual(
		fieldName: string,
		lowerLimit?: Day | Date,
		level: FieldRuleLevel = FieldRuleLevel.Warning,
		message?: string,
	): FieldRule {
		return new FieldRule(
			v => this.isValidDateCompare(v, (d1, d2) => d1.getTime() >= d2.getTime(), lowerLimit),
			() => message ?? formatMessage(this.lang.Validation_DateGreaterThanOrEqual_Must, formatDate(lowerLimit)),
			fieldName,
			level,
			FieldRuleType.DateGreaterThanOrEqual,
		);
	}

	/**
	 * This rule depends on `DateExists` and should be placed after `DateExists` to get the correct error message.
	 * For example: `[factory.Required, factory.DateFormat, factory.DateExists, factory.DateGreaterThanOrEqual]`
	 * Only validate if an input is an exist date
	 */
	DateIsNotOneOf(
		fieldName: string,
		invalidDates: Day[],
		level: FieldRuleLevel = FieldRuleLevel.MustFixNow,
		message?: string,
	): FieldRule {
		return new FieldRule(
			v => {
				// eslint-disable-next-line deprecate/function
				const value = v instanceof Day ? v.getRawValue() : toString(v);
				return !invalidDates.some(id => Day.parseString(value, { iso: true }).equals(id));
			},
			() => formatMessage(message || this.lang.Validation_DateIsNotOneOf_Must),
			fieldName,
			level,
			FieldRuleType.DateIsNotOneOf,
		);
	}

	DateNotInRange(
		fieldName: string,
		invalidDateRanges: RangeModifier[],
		level: FieldRuleLevel = FieldRuleLevel.MustFixNow,
		message?: string,
	): FieldRule {
		return new FieldRule(
			v => {
				const value = v instanceof Day ? v.getRawValue() : `${v}`;
				const day = Day.parseString(value, { iso: true }).toDate();
				return !invalidDateRanges.some(f => f.from && day >= f.from && f.to && day <= f.to);
			},
			() => formatMessage(message || this.lang.Validation_DateIsNotOneOf_Must),
			fieldName,
			level,
			FieldRuleType.DateIsNotOneOf,
		);
	}

	/**
	 * This rule depends on `DateExists` and should be placed after `DateExists` to get the correct error message.
	 * For example: `[factory.Required, factory.DateFormat, factory.DateExists, factory.DateLessThan]`
	 * Only validate if an input is an exist date */
	DateLessThan(
		fieldName: string,
		upperLimit?: Day | Date,
		level: FieldRuleLevel = FieldRuleLevel.Warning,
		message?: string,
	): FieldRule {
		return new FieldRule(
			v => this.isValidDateCompare(v, (d1, d2) => d1.getTime() < d2.getTime(), upperLimit),
			() => formatMessage(message ?? this.lang.Validation_DateLessThan_Must, formatDate(upperLimit)),
			fieldName,
			level,
			FieldRuleType.DateLessThan,
		);
	}

	/**
	 * This rule depends on `DateExists` and should be placed after `DateExists` to get the correct error message.
	 * For example: `[factory.Required, factory.DateFormat, factory.DateExists, factory.DateLessThanOrEqual]`
	 * Only validate if an input is an exist date */
	DateLessThanOrEqual(
		fieldName: string,
		upperLimit?: Day | Date,
		level: FieldRuleLevel = FieldRuleLevel.Warning,
		message?: string,
	): FieldRule {
		return new FieldRule(
			v => this.isValidDateCompare(v, (d1, d2) => d1.getTime() <= d2.getTime(), upperLimit),
			() => formatMessage(message ?? this.lang.Validation_DateLessThanOrEqual, formatDate(upperLimit)),
			fieldName,
			level,
			FieldRuleType.DateLessThanOrEqual,
		);
	}

	/**
	 * Validates if the input passes personnummer checksum and is in the format of either YYYYMMDDXXXX or YYYYMMDD-XXXX
	 */
	PersonNumber(fieldName: string, level: FieldRuleLevel = FieldRuleLevel.MustFixBeforeSend): FieldRule {
		return new FieldRule(
			v => {
				if (!v || typeof v !== "string") {
					return true;
				}
				if (!/^\d{8}[-]?\d{4}$/.test(v)) {
					return false;
				}

				// Validate date
				// FE 2025-01-09: If updating logic here make sure to check the corresponding logic in the backend
				// in OrgPersonalNumberHelper.IsPersonalNumber and OrgPersonalNumberHelper.IsSamordningsNumber
				const datePart = v.slice(0, 8); // Regex above ensures that date part is 8 characters long
				const adjustedDay = parseInt(v.slice(datePart.length - 2, datePart.length), 10) % 60; // Adjust for possible samordningsnummer which adds 60 to the day
				const adjustedDatePart = datePart.slice(0, datePart.length - 2) + adjustedDay.toString().padStart(2, "0");

				const date = parseDate(adjustedDatePart, { format: "yyyyMMdd" });
				if (isNaN(date.getTime())) {
					return false; // Invalid date
				}

				return validateNumberWithLuhnChecksum(v.slice(2));
			},
			() =>
				`${this.lang.ShouldBeOfTheFormat} YYYYMMDDXXXX ${this.lang._OR} YYYYMMDD-XXXX ${this.lang.CorrectPersonalNumber}`,
			fieldName,
			level,
			FieldRuleType.PersonNumber,
		);
	}

	/**
	 * Validates if the input passes personnummer checksum and is in the format of one of the following:
	 * YYMMDDXXXX, YYMMDD-XXXX, YYYYMMDDXXXX or YYYYMMDD-XXXX
	 * You can use this instead of `PersonNumber` if you are certain that the backend casts the input with `OrgPersonalNumberHelper.TryToTwelveLength`
	 */
	PersonNumberLoose(fieldName: string, level: FieldRuleLevel = FieldRuleLevel.MustFixBeforeSend): FieldRule {
		return new FieldRule(
			v => {
				if (!v || typeof v !== "string") {
					return true;
				}
				if (!/^(\d{6}|\d{8})[-]?\d{4}$/.test(v)) {
					return false;
				}

				const digits = v.replace(/\D/g, "");

				// Validate date
				// FE 2025-01-09: If updating logic here make sure to check the corresponding logic in the backend
				// in OrgPersonalNumberHelper.IsPersonalNumber and OrgPersonalNumberHelper.IsSamordningsNumber
				const datePart = digits.length === 12 ? digits.slice(0, 8) : digits.slice(0, 6);
				const adjustedDay = parseInt(v.slice(datePart.length - 2, datePart.length), 10) % 60; // Adjust for possible samordningsnummer which adds 60 to the day
				const adjustedDatePart = datePart.slice(0, datePart.length - 2) + adjustedDay.toString().padStart(2, "0");

				const date =
					digits.length === 12
						? parseDate(adjustedDatePart, { format: "yyyyMMdd" })
						: parseDate(adjustedDatePart, { format: "yyMMdd" });
				if (isNaN(date.getTime())) {
					return false; // Invalid date
				}

				return validateNumberWithLuhnChecksum(digits.length === 12 ? digits.slice(2) : digits);
			},
			() =>
				`${this.lang.ShouldBeOfTheFormat} YYMMDDXXXX, YYMMDD-XXXX, YYYYMMDDXXXX ${this.lang._OR} YYYYMMDD-XXXX ${this.lang.CorrectPersonalNumber}`,
			fieldName,
			level,
			FieldRuleType.PersonNumber,
		);
	}

	ConfirmationCode(fieldName: string, level: FieldRuleLevel = FieldRuleLevel.MustFixBeforeSend): FieldRule {
		return new FieldRule(
			v => {
				if (!v) {
					return false;
				}
				const regex = /^\d{4}$/;
				return regex.test(v);
			},
			() => `${this.lang.ShouldBeOfTheFormat} nnnn`,
			fieldName,
			level,
			FieldRuleType.ConfirmationCode,
		);
	}

	NotEqualTo(
		fieldName: string,
		value: string,
		translatedValue: string,
		level: FieldRuleLevel = FieldRuleLevel.MustFixBeforeSend,
	): FieldRule {
		return new FieldRule(
			v => {
				if (v === value) {
					return false;
				}
				return true;
			},
			() => formatMessage(this.lang.Validation_NotEqualTo_Must, translatedValue),
			fieldName,
			level,
			FieldRuleType.NotEqualTo,
		);
	}

	MinLength(fieldName: string, minLength: number, level: FieldRuleLevel = FieldRuleLevel.MustFixBeforeSend): FieldRule {
		return new FieldRule(
			v => (typeof v === "string" || Array.isArray(v)) && minLength <= v.length,
			() => formatMessage(this.lang.Validation_MinLength, minLength),
			fieldName,
			level,
			FieldRuleType.MinLength,
		);
	}

	MaxLength(fieldName: string, maxLength: number, level: FieldRuleLevel = FieldRuleLevel.MustFixBeforeSend): FieldRule {
		return new FieldRule(
			v => typeof v == "undefined" || (typeof v === "string" && v.length <= maxLength),
			() => formatMessage(this.lang.Validation_MaxLength, maxLength),
			fieldName,
			level,
			FieldRuleType.MaxLength,
		);
	}

	LengthBetween(
		fieldName: string,
		minLength: number,
		maxLength: number,
		level: FieldRuleLevel = FieldRuleLevel.MustFixBeforeSend,
	): FieldRule {
		return new FieldRule(
			v => typeof v === "string" && minLength <= v.length && v.length <= maxLength,
			() => formatMessage(this.lang.Validation_RangeLength, minLength, maxLength),
			fieldName,
			level,
			FieldRuleType.MinLength,
		);
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	NotInList(fieldName: string, list: any[], level: FieldRuleLevel = FieldRuleLevel.MustFixBeforeSend): FieldRule {
		return new FieldRule(
			v => !list.includes(v),
			v => formatMessage(this.lang.Validation_NotInList, v),
			fieldName,
			level,
			FieldRuleType.NotInList,
		);
	}

	AtLeastOneInList(fieldName: string, level: FieldRuleLevel = FieldRuleLevel.MustFixBeforeSend): FieldRule {
		return new FieldRule(
			v => v.length > 0,
			() => this.lang.Validation_AtLeastOnInList,
			fieldName,
			level,
			FieldRuleType.AtLeastOneInList,
		);
	}

	Alphanumeric(fieldName: string, level: FieldRuleLevel = FieldRuleLevel.MustFixBeforeSend): FieldRule {
		return new FieldRule(
			v => isAlphanumeric(v),
			() => formatMessage(this.lang.Validation_IsNotAlphanumeric, fieldName),
			fieldName,
			level,
			FieldRuleType.Alphanumeric,
		);
	}

	BbaPaymentMessage(fieldName: string, level: FieldRuleLevel = FieldRuleLevel.MustFixBeforeSend): FieldRule {
		return new FieldRule(
			v => /^[ a-zA-Z0-9åäöÅÄÖ,.-]+$/.test(v),
			() => formatMessage(this.lang.Validation_AllowedCharacters, fieldName),
			fieldName,
			level,
			FieldRuleType.Alphanumeric,
		);
	}

	MatchRegexp(
		fieldName: string,
		level: FieldRuleLevel = FieldRuleLevel.MustFixBeforeSend,
		regexp: RegExp,
		caseInsensitive: boolean,
		errorMessage: string,
	): FieldRule {
		return new FieldRule(
			v => typeof v === "string" && regexp.test(caseInsensitive ? v.toLowerCase() : v),
			() => errorMessage,
			fieldName,
			level,
			FieldRuleType.Alphanumeric,
		);
	}

	NoWhitespace(fieldName: string, level: FieldRuleLevel = FieldRuleLevel.MustFixBeforeSend): FieldRule {
		return new FieldRule(
			v => containsNoWhitespace(v),
			() => formatMessage(this.lang.Validation_ContainsWhitespace, fieldName),
			fieldName,
			level,
			FieldRuleType.NoWhitespace,
		);
	}

	BankgiroNumber(fieldName: string, level: FieldRuleLevel = FieldRuleLevel.MustFixBeforeSend): FieldRule {
		return new FieldRule(
			v => PGorBG.validate(v) === GiroTypes.Bankgiro,
			() => formatMessage(this.lang.FieldNotCorrectFormat, fieldName),
			fieldName,
			level,
			FieldRuleType.BankgiroNumber,
		);
	}

	PlusgiroNumber(fieldName: string, level: FieldRuleLevel = FieldRuleLevel.MustFixBeforeSend): FieldRule {
		return new FieldRule(
			v => PGorBG.validate(v) === GiroTypes.Plusgiro,
			() => formatMessage(this.lang.FieldNotCorrectFormat, fieldName),
			fieldName,
			level,
			FieldRuleType.PlusgiroNumber,
		);
	}

	SeBankClearingNumber(fieldName: string, level: FieldRuleLevel = FieldRuleLevel.MustFixBeforeSend): FieldRule {
		return new FieldRule(
			v => {
				if (!v) {
					return true;
				}
				return !!BankAccountValidator.validateClearingNumber(v ?? "");
			},
			() => formatMessage(this.lang.FieldNotCorrectFormat, fieldName),
			fieldName,
			level,
			FieldRuleType.SeBankClearingNumber,
		);
	}

	SeBankAccountNumber(
		fieldName: string,
		clearingNumber: string,
		level: FieldRuleLevel = FieldRuleLevel.MustFixBeforeSend,
	): FieldRule {
		return new FieldRule(
			accountNumber => {
				if (!accountNumber) {
					return true;
				}
				return !!BankAccountValidator.validate(`${clearingNumber}${accountNumber}`);
			},
			() => formatMessage(this.lang.FieldNotCorrectFormat, fieldName),
			fieldName,
			level,
			FieldRuleType.SeBankAccountNumber,
		);
	}

	Iban(fieldName: string, level: FieldRuleLevel = FieldRuleLevel.MustFixBeforeSend): FieldRule {
		return new FieldRule(
			// If you ever want to update this method to make it more strict,
			// Beware that the country code in IBAN could be different from country code, for example Guernsey (GG) has their IBAN starting with UK's (GB).
			v => {
				if (!v || typeof v !== "string") {
					return true;
				}
				return !!IBAN.validate(v);
			},
			() => formatMessage(this.lang.FieldNotCorrectFormat, fieldName),
			fieldName,
			level,
			FieldRuleType.Iban,
		);
	}

	Bic(fieldName: string, level: FieldRuleLevel = FieldRuleLevel.MustFixBeforeSend): FieldRule {
		return new FieldRule(
			v => {
				// https://www.swift.com/standards/data-standards/bic#Overviewandstructure
				// If you ever want to update this method to make it more strict,
				// beware that the country codes in IBAN & BIC might NOT always be the same.
				// for such edge cases checkout https://www.europeanpaymentscouncil.eu/document-library/other/epc-list-sepa-scheme-countries
				return /^[A-Z0-9]{4}([A-Z]{2})[A-Z0-9]{2}(?:[A-Z0-9]{3})?$/.test(v);
			},
			() => formatMessage(this.lang.FieldNotCorrectFormat, fieldName),
			fieldName,
			level,
			FieldRuleType.Bic,
		);
	}

	OcrNumber(fieldName: string, level: FieldRuleLevel = FieldRuleLevel.MustFixBeforeSend): FieldRule {
		return new FieldRule(
			v => {
				// Since `validateNumberWithLuhnChecksum` automatically strips non-digits in it's implementation
				// Catch potentially invalid value before proceeding
				if (/\D/.test(v)) {
					return false;
				}

				return validateNumberWithLuhnChecksum(v);
			},
			() => formatMessage(this.lang.FieldNotCorrectFormat, fieldName),
			fieldName,
			level,
			FieldRuleType.OcrNumber,
		);
	}

	PhoneNumber(fieldName: string, level: FieldRuleLevel = FieldRuleLevel.MustFixBeforeSend): FieldRule {
		return new FieldRule(
			v => {
				if (!v) {
					return true;
				}
				return /^\+?[\d -]+$/.test(v);
			},
			() => formatMessage(this.lang.FieldNotCorrectFormat, fieldName),
			fieldName,
			level,
			FieldRuleType.PhoneNumber,
		);
	}

	static ALLOWED_DOMAIN = [
		"app.bokio.se",
		"www.bokio.se",
		"bokio.se",
		"app.bokio.co.uk",
		"www.bokio.co.uk",
		"bokio.co.uk",
		"localhost:4921", //Currently doesn't matter because the regex is
		"bokio-staging.azurewebsites.net",
		"uk-staging-main-web.azurewebsites.net",
	];
	NotContainExternalLink(fieldName: string, level: FieldRuleLevel = FieldRuleLevel.MustFixNow): FieldRule {
		return new FieldRule(
			v => {
				if (v == undefined || typeof v !== "string") {
					return true;
				}
				const matches = v.match(
					/((http|ftp|https):\/\/[\w\-_]+(\.[\w\-_]+)+([\w\-\.,@?^=%&amp;:/~\+#]*[\w\-\@?^=%&amp;/~\+#])?)/gi,
				);
				return matches ? matches.every(m => FieldRuleFactory.ALLOWED_DOMAIN.some(ad => m.includes(ad))) : true;
			},
			() => formatMessage(this.lang.FieldMustContainNoExternalLink, fieldName.toLocaleLowerCase()),
			fieldName,
			level,
			FieldRuleType.NotContainExternalLink,
		);
	}

	NotContainOnlySpaces(fieldName: string, level: FieldRuleLevel = FieldRuleLevel.MustFixBeforeSend): FieldRule {
		return new FieldRule(
			v => typeof v === "string" && !!v.trim().length,
			() => formatMessage(this.lang.Validation_NotContainOnlySpaces, fieldName),
			fieldName,
			level,
			FieldRuleType.NotContainOnlySpaces,
		);
	}

	SupportedCurrency(
		fieldName: string,
		currencyList: CurrencyInfoDto[],
		level: FieldRuleLevel = FieldRuleLevel.MustFixBeforeSend,
	): FieldRule {
		return new FieldRule(
			v => currencyList.some(x => x.Code === v),
			() => formatMessage(this.lang.Validation_IsNotSupportedCurrency, fieldName),
			fieldName,
			level,
			FieldRuleType.SupportedCurrency,
		);
	}

	/**
	 * Validate a account number in an account selector
	 * @param fieldName
	 * @param accountInput The input of the input field for account selector. This can have a value and not the actual field because
	 * no account is selecte. Only text/digits is typed.
	 * @param accounts All accounts possible to select
	 * @param level
	 * @returns
	 */
	AccountNumber(
		fieldName: string,
		accountInput: string,
		accounts: AccountOption[],
		level: FieldRuleLevel = FieldRuleLevel.MustFixNow,
	): FieldRule {
		const accountingLang = AccountingLangFactory();
		const fourDigitsRegex = new RegExp(/[0-9]{4}/);
		const accountNumber = fourDigitsRegex.test(accountInput.trim()) ? parseInt(accountInput.trim()) : undefined;
		return new FieldRule(
			v => {
				return v && v > 0 && accounts.some(a => a.AccountNumber === v);
			},
			() =>
				accountNumber === undefined
					? accountingLang.Error_AccountNotValid
					: accountNumber !== undefined && !accounts.some(a => a.AccountNumber === accountNumber)
						? formatMessage(accountingLang.Error_AccountNotInChartOfAccounts, accountInput)
						: accountingLang.Error_AccountMustBeSelected,
			fieldName,
			level,
			FieldRuleType.AccountNumber,
		);
	}

	CountryNotSupported(
		fieldName: string,
		supportedCountries?: CountryInfoDto[],
		level: FieldRuleLevel = FieldRuleLevel.Warning,
	): FieldRule {
		const invoiceLang = InvoicesLangFactory();
		return new FieldRule(
			v => {
				if (!v || supportedCountries === undefined) {
					return true;
				}
				return supportedCountries.some(x => x.Code === v);
			},
			() => invoiceLang.Transactions_not_supported_for_country,
			fieldName,
			level,
			FieldRuleType.CountryNotSupported,
		);
	}
}
