import BokioLogo from "@bokio/components/BokioLogo/BokioLogo";
import { cssVariableForBgColor } from "@bokio/designsystem/theme";
import { mergeClassNames } from "@bokio/utils/classes";

import { FloatingHelp } from "../FloatingHelp/FloatingHelp";

import type { CssVariableColorNames } from "@bokio/designsystem/theme/theme";
import type * as React from "react";

import * as styles from "./layoutFull.scss";

export type LayoutFullType = "legacy" | "plain";

interface LayoutFullProps {
	noTopBarMode: boolean;
	noIconMode?: boolean;

	backgroundColor?: CssVariableColorNames;
	type?: LayoutFullType;
}

const getLayoutFullTypeClass = (type: LayoutFullType = "legacy"): string => {
	return type === "legacy" ? styles.legacyLayout : styles.plainLayout;
};

export const LayoutFull: React.FC<React.PropsWithChildren<LayoutFullProps>> = props => {
	const layoutFullTypeClass = getLayoutFullTypeClass(props.type);

	return (
		<div
			className={mergeClassNames(styles.fullLayoutWrapper, layoutFullTypeClass)}
			style={cssVariableForBgColor(props.backgroundColor, "--bgColor")}
		>
			{!props.noIconMode && (
				<div className={styles.logo}>
					<BokioLogo size="48" inverted={false} imageClassName={styles.logoImage} />
				</div>
			)}

			{props.children}
			{props.noTopBarMode && <FloatingHelp />}
		</div>
	);
};
