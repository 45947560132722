import { Article } from "@bokio/designsystem/components/TypographicElements/TypographicElements";
import { Badge } from "@bokio/elements/Badge/Badge";
import PageTitle from "@bokio/elements/PageTitle/PageTitle";
import { mergeClassNames } from "@bokio/utils/classes";

import { LayoutFull } from "../LayoutFull/LayoutFull";

import type { BadgeColor } from "@bokio/elements/Badge/Badge";
import type * as React from "react";

import * as styles from "./singleFormPage.scss";

export interface SingleFormPageProps {
	/**
	 * This is displayed at the top of the box content of the page.
	 * We use it in the sign up flows for indicating the current step.
	 */
	indicator?: React.ReactNode;

	illustration?: React.ReactNode;

	title: string;

	/**
	 * This is usually a short text that is added right below the title.
	 */
	preamble?: React.ReactNode;

	children?: React.ReactNode;

	/**
	 * The epilogue is added below the main page box and is used for extra content in the page.
	 * We use it, for example, for displaying which country the user is logging in to.
	 */
	epilogue?: React.ReactNode;

	/**
	 * This is a bar that is appended to the bottom of the page's box, but it's still part of the page's main content.
	 */
	contentFooter?: React.ReactNode;

	className?: string;

	pageTitle?: string;

	status?: { name: string; color: BadgeColor };

	/** Should be set for pages on GuestRoute, will show popup help rather than topbar */
	noTopBarMode: boolean;

	/**
	 * Determines whether to show the title.
	 * Defaults to `true` if not provided.
	 */
	showTitle?: boolean;
}

const SingleFormPage: React.FC<SingleFormPageProps> = ({
	indicator,
	illustration,
	title,
	preamble,
	children,
	epilogue,
	contentFooter,
	className,
	pageTitle,
	status,
	noTopBarMode,
	showTitle = true, // Default value set here
}) => (
	<LayoutFull noTopBarMode={noTopBarMode}>
		<PageTitle title={pageTitle || title} />
		<Article resetHeadingLevelTo={1}>
			<div className={mergeClassNames(styles.signUp, className)}>
				<div className={styles.content}>
					{indicator && <div className={styles.indicator}>{indicator}</div>}
					{illustration && <div className={styles.illustration}>{illustration}</div>}
					<div className={styles.header}>
						{status && <Badge {...status} noMargin />}
						{showTitle && <h1 className={status && styles.smallerMarginHeader}>{title}</h1>}
						{preamble && <div className={styles.preamble}>{preamble}</div>}
					</div>
					{children}
				</div>
				{contentFooter && <div className={styles.contentFooter}>{contentFooter}</div>}
			</div>
		</Article>
		{epilogue && <div className={styles.epilogue}>{epilogue}</div>}
	</LayoutFull>
);

export default SingleFormPage;
