import * as React from "react";

import { Button, ButtonGroup } from "@bokio/designsystem/components/Button";
import { SG } from "@bokio/designsystem/components/SpacingGroup/SpacingGroup";
import { Div, Paragraph } from "@bokio/designsystem/components/TypographicElements/TypographicElements";
import { useDeviceQuery } from "@bokio/elements/DeviceQuery/useDeviceQuery";
import { Link } from "@bokio/elements/Link/Link";
import { useDeviceUserAgent } from "@bokio/hooks/useDeviceUserAgent/useDeviceUserAgent";
import { GeneralLangFactory } from "@bokio/lang";
import * as m from "@bokio/mobile-web-shared/core/model/model";
import { useCompanyUser } from "@bokio/shared/state/requests";
import { trackTrace } from "@bokio/utils/t";

import { BankIdHintCode } from "../BankIdHintCode/BankIdHintCode";
import { BankIdLogo } from "../BankIdLogo/BankIdLogo";
import { QrCode } from "../QrCode/QrCode";

import type { OsChecks } from "@bokio/hooks/useDeviceUserAgent/useDeviceUserAgent";

import BankIdHintCodeEnum = m.Bokio.Common.Contract.BankId.BankIdHintCode;
import BankIdCollectionStatus = m.Bokio.Common.Contract.BankId.BankIdCollectionStatus;

const getAutostartUrl = (bankIdAutoStartToken: string, checks: OsChecks) => {
	// On Android, a redirect of null will automatically open the previous app (ideally the browser)
	if (!checks.isIOS) {
		return `bankid:///?autostarttoken=${bankIdAutoStartToken}&redirect=null`;
	}

	// On iOS the redirect url is required to navigate away from the BankID app, see https://developers.bankid.com/getting-started/frontend/autostart

	// For Safari, `com-apple-mobilesafari-tab://` exists, but it's not documented. So stick to assuming it's the default browser, for now.
	// The hashtag is needed for Safari, to prevent the browser from navigating
	let redirectUrl = location.href + "#";

	// If you do make any changes here, please test the different browsers on iOS and try to update https://dev.azure.com/bokiodev/Voder/_wiki/wikis/Voder.wiki/1522/BankID-on-Mobile-Web
	// These are not entirely correct usages of the URL schemes, but they do work with returning to the browser, without any navigation
	if (checks.isChromeOnIOs) {
		redirectUrl = "googlechromes://";
	} else if (checks.isFirefoxOnIOs) {
		redirectUrl = "firefox://";
	} else if (checks.isEdgeOnIOs) {
		redirectUrl = "microsoft-edge-https://";
	} else if (checks.isGoogleAppOnIOs) {
		// This is the Google App Services browser
		redirectUrl = "googleapp://open-url?url=" + encodeURIComponent(location.href + "#");
	} else if (checks.isFBOnIOs) {
		// Facebook browser
		// TODO: launching BankID from the Facebook browser doesn't seem to work with the bankid:// schema, but it's sort of hard to test
		redirectUrl = "fb://";
	}

	return `bankid:///?autostarttoken=${bankIdAutoStartToken}&redirect=${redirectUrl}`;
};

export interface BankIdSecureStartProps {
	autoStartToken?: string;
	bankIdQrCode?: string;
	collectionStatus?: BankIdCollectionStatus;
	hintCode?: BankIdHintCodeEnum;

	onStartPollingQrCode?: () => void;
	onStopPollingQrCode?: () => void;
	/**
	 * BokioPay login flow polls the collect endpoint separately,
	 * so {@link onStartPollingQrCode} doesn't need to run in auto start view (=the default view in mobile) for BokioPay;
	 * but for Svea flow the collect polliing is the same call for getting the QR code,
	 * we use this flag for temporarily separating the flow before we clean up the code between branches.
	 */
	isBokioPayLogin?: boolean;

	onBankIdOpen?: () => void;
}

export type BankIdSecureStartLoginView = "autostart" | "qr";

export const BankIdSecureStart = ({
	autoStartToken,
	bankIdQrCode,
	collectionStatus,
	hintCode,
	onStartPollingQrCode,
	onStopPollingQrCode,
	isBokioPayLogin,
	onBankIdOpen,
}: BankIdSecureStartProps) => {
	const generalLang = GeneralLangFactory();
	const { isMobile } = useDeviceQuery();
	const { company } = useCompanyUser();
	const [loginView, setLoginView] = React.useState<BankIdSecureStartLoginView>(isMobile ? "autostart" : "qr");
	const [appLaunchedAutomatically, setAppLaunchedAutomatically] = React.useState(false);
	const osChecks = useDeviceUserAgent();

	const launchBankIdApp = (openedAutomatically: boolean, bankIdAutoStartToken: string) => {
		setAppLaunchedAutomatically(openedAutomatically);

		const autostartUrl = getAutostartUrl(bankIdAutoStartToken, osChecks);

		// EE: Log context to be able to figure out why the polling just stops on iPhones.
		trackTrace("BankIdSecureStart", { now: Date.now().toString(), companyId: company?.Id || "" });
		onBankIdOpen?.();
		window.location.href = autostartUrl;
	};

	React.useEffect(() => {
		if (autoStartToken && loginView === "autostart") {
			launchBankIdApp(true, autoStartToken);
		}

		// TODO: Fix functions in the hook to make sure they are proper useCallback
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [autoStartToken, loginView]);

	React.useEffect(() => {
		if (!autoStartToken) {
			return;
		}

		if (isBokioPayLogin && loginView === "autostart") {
			// BokioPay polls the collect endpoint separately,
			// so we don't need to poll the QR code when we don't need to show the QR code.
			return;
		}

		// For BokioPay: Poll QR code
		// For Svea: Poll collect & QR code
		onStartPollingQrCode?.();

		return () => {
			onStopPollingQrCode?.();
		};

		// TODO: Fix functions in the hook to make sure they are proper useCallback
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [autoStartToken, isBokioPayLogin, loginView]);

	return (
		<SG align="center" gap="16">
			<BankIdLogo />
			{hintCode && collectionStatus && (
				<BankIdHintCode
					bankIdSecureStartLoginView={loginView}
					hintCode={hintCode}
					collectionStatus={collectionStatus}
					triedToLaunchAppAutomatically={appLaunchedAutomatically}
				/>
			)}

			{loginView == "qr" ? (
				<Div>
					{bankIdQrCode && <QrCode data={bankIdQrCode} />}
					<Div>
						<Link onClick={() => setLoginView("autostart")}>{generalLang.BankID_OpenOnThisDevice}</Link>
					</Div>
				</Div>
			) : (
				<Div>
					<SG gap="8">
						<Paragraph textCenter>{generalLang.BankId_NotOpening}</Paragraph>
						<ButtonGroup direction="column">
							<Button
								type="button"
								icon="bankid-solid"
								iconAlign="right"
								margin={["top", "bottom"]}
								onClick={() => autoStartToken && launchBankIdApp(false, autoStartToken)}
								appearance="primary"
							>
								{generalLang.BankId_Open}
							</Button>
							<Button
								type="button"
								icon="bankid-solid"
								iconAlign="right"
								margin={["top", "bottom"]}
								onClick={() => setLoginView("qr")}
								appearance="secondary"
							>
								{generalLang.BankID_OpenOnOtherDevice}
							</Button>
						</ButtonGroup>
					</SG>
				</Div>
			)}
		</SG>
	);
};
