import { SG } from "@bokio/designsystem/components/SpacingGroup/SpacingGroup";

import { LoadingText } from "./LoadingText";
import { RenderRequestError } from "./RenderRequestError";

import type { LoadingProps } from "./LoadingProps";

import * as styles from "./loadingList.scss";

export function LoadingList({ request, requests, children, hideSoftErrors }: LoadingProps) {
	const renderItem = ({ grayBg }: { grayBg?: boolean } = {}) => {
		const width = Math.random() * 100 + "%";
		return (
			<div className={styles.border}>
				<SG background={grayBg ? "ui-6" : undefined} padding={[12, 16]}>
					<LoadingText width={width} />
				</SG>
			</div>
		);
	};

	return (
		<RenderRequestError request={request} requests={requests} showWhenLoaded={children} hideSoftErrors={hideSoftErrors}>
			<SG>
				{renderItem({ grayBg: true })}

				{renderItem()}
				{renderItem()}
				{renderItem()}
				{renderItem()}
				{renderItem()}
			</SG>
		</RenderRequestError>
	);
}
